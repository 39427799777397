import React from "react";
import { Container } from "react-bootstrap";
import Slider from "../Slider/Slider";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <div>
          <h2 className="main-title-english">Aviation English for</h2>
          <Slider />
        </div>
        <div className="go-to go-to-next">
          <a
            href="#about"
            aria-label="go to about section"
            className="hidden-text"
          >
            <span></span>
            <span style={{ display: "none" }}>Go to About section</span>
          </a>
        </div>
      </Container>
    </section>
  );
}

export default Home;
